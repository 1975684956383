function setCookie(name, value, days) {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

// 
function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

// 
function eraseCookie(name) {
  document.cookie = name + '=; Max-Age=-99999999;';
}

// 
function fbGetLoginStatus() {  
  FB.getLoginStatus(function (response) {
    statusChangeCallback(response);
  });
}

// 
function trackGoogleAnalytics(event) {  

  // send page view unless they are an admin
  // FIXME: should not be in template
  var isAdmin = getCookie('admin');
  var userId  = getCookie('userId');

  // Tag data
  var gtagId       = document.querySelector('meta[name="gtagId"]');
  var isProduction = (location.hostname == "fertilityspace.io");

  if (userId != undefined) {
    gtag('config', gtagId.content, {
      user_id: userId.content,
      send_page_view: false
    })  
  } else {
    gtag('config', gtagId.content, {
      send_page_view: false
    })
  }

  if (getCookie('_prefers_color_scheme') != undefined ) {
    var prefersColorScheme = getCookie('_prefers_color_scheme')
    gtag('set', 'user_properties', {
      prefers_color_scheme: prefersColorScheme,
    });
  }

  if (isAdmin == "true" || isProduction == false) {
    // console.log('skip trackGoogleAnalytics()');
    gtag('set', 'user_properties', {
      admin: 'true',
    });
  } else {
    gtag('event', 'page_view')
  }

  // Send analytics event set by cookie
  if (getCookie('_fert_event') != undefined && isAdmin == undefined ) {
    var eventName = getCookie('_fert_event')
    gtag('event', eventName)
    eraseCookie('_fert_event')
  }
}

function sendGoogleAnalyticsEvent(event_name, opts) {
  var isAdmin = getCookie('admin');
  var isProduction = (location.hostname == "fertilityspace.io");

  if (isAdmin != undefined && isAdmin == "true" || isProduction == false) {
    console.log('skip sendGoogleAnalyticsEvent()');
    gtag('set', 'user_properties', {
      admin: 'true',
    });
  } else {
    if (typeof (gtag) === "function") {
      gtag('event', event_name, opts)
    }
  }
}

// FIXME
window.dataLayer = window.dataLayer || []
function gtag() { dataLayer.push(arguments) }
gtag('js', new Date())


// document.addEventListener('turbolinks:load', trackGoogleAnalytics) // fixme when turbo works again
document.addEventListener("turbo:load", trackGoogleAnalytics)